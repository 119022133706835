.modal-border {
    /* max-height: 90% !important; */
    border: 2px solid #ccc;
    border-radius: 3px;
    padding:2px;
  }

  .modal-content {
    /* 80% of window height */
    height: 90%;
}

.modal-dialog {
    height: 90% !important;
    max-width: 90% !important;
    width: 90%;
  }